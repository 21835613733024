$(function () {
    $('input').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue',
        increaseArea: '20%' /* optional */
    });

    $(document).on('click', '#btnLogin', function(e) {
        e.preventDefault();
        if( $('#formLogin').smkValidate() ){
            var pass = $('#senhaLogin').val();
            var email = $('#emailLogin').val();
            Swal.fire({
                title: 'Validando login, aguarde...',
                showConfirmButton: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                }
            });
            $.ajax({
                type: "POST",
                data: {emailLogin: email, senhaLogin: pass},
                url: "envia-login",
                success: function (result) {
                    var res = result.split('|');
                    Swal.fire({
                        type: res[3],
                        title: res[1],
                        text: res[2],
                        showConfirmButton: false,
                        /*timer: 3000,
                        onClose: () => {
                            setTimeout(function(){
                                if(parseInt(res[0].charAt(res[0].length-1)) == 1) {
                                    location.href = './';
                                }
                            },500)
                        }*/
                    });
                    setTimeout(function(){
                        if(parseInt(res[0].charAt(res[0].length-1)) == 1) {
                            location.href = './';
                        }else{
                            Swal.close();
                        }
                    },2000)
                }
            });
        }
    });

/*    $("#formLogin").validate({
        submitHandler: function (form) {
            $(form).ajaxSubmit({
                //target: 'status-envio',
                beforeSubmit: function () {
                    Swal.fire({
                        title: 'Validando login, aguarde...',
                        showConfirmButton: false,
                        onBeforeOpen: () => {
                            Swal.showLoading()
                        }
                    });
                    $('#btnLogin').attr('disabled', true);
                },
                success: function (result) {
                    result = result.split('|');
                    if(parseInt(result[0].charAt(result[0].length-1)) == 1){
                        Swal.fire({
                            type: 'success',
                            title: result[1],
                            text: result[2],
                            showConfirmButton: false,
                            timer: 2000,
                            onClose: () => {
                                setTimeout(function(){
                                    location.href = './';
                                },500)
                            }
                        });
                    }else{
                        Swal.fire({
                            type: 'error',
                            title: result[1],
                            text: result[2],
                            showConfirmButton: false,
                            timer: 3000,
                            onClose: () => {
                                setTimeout(function(){
                                    $('#btnLogin').attr('disabled', false);
                                },500)
                            }
                        });
                    }
                }
            });
        }
    });*/
});